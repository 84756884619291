/* page event */
.page-wallet-event .title {
  width: 100%;
}
.page-wallet-event .title {
  font-size: 32px;
  color: #e67548;
  width: 100%;
  text-align: center;
}

.table-wallet-event .ant-table,
.table-wallet-event .ant-table-thead > tr > th,
.table-wallet-event .ant-table-tbody > tr {
  background: transparent;
}

.table-wallet-event .ant-table-thead > tr > th {
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-top: 1px solid white;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}

.table-wallet-event .ant-pagination-prev button,
.table-wallet-event .ant-pagination-next button {
  background: linear-gradient(180deg, #fa804d, #773920);
  outline: unset;
  border-radius: 8px;
  border: 0;
  color: #fff;
  height: auto;
}

.custom-pagination .page {
  color: white;
  font-size: 16px;
}

.table-wallet-event .ant-pagination {
  display: none;
}

@media (max-width: 767px) {
  .page-wallet-event .title {
    font-size: 24px;
  }

  .table-wallet-event .ant-table-thead > tr > th {
    font-size: 16px;
  }
}
