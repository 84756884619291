.live-result-item {
    background-color: #525252;
    padding: 1px;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    font-size: 12px !important;
    margin-top: 4px;
    margin-right: 4px;
}

.live-result-item .banker {
    width: 100%;
    height: 100%;
    background-color: #ca0200;
    line-height: 17px;
    text-align: center;
    border-radius: 50%;
    color: white;
  }
  
  .live-result-item .player {
    width: 100%;
    height: 100%;
    background-color: #0cb9f2;
    line-height: 17px;
    text-align: center;
    border-radius: 50%;
    color: white;
  }
  
  .live-result-item .tie {
    width: 100%;
    height: 100%;
    background-color: #24bc00;
    line-height: 17px;
    text-align: center;
    border-radius: 50%;
    color: white;
  }
  
  .live-result-item .non {
    width: 100%;
    height: 100%;
  }

  
.live-resul-table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 130px;
  max-width: 260px;
  margin: auto;
}

@media (max-width: 479px) {
  .live-resul-table {
    height: 110px;
    width: 240px;
  }
    .live-result-item {
        padding: 0px;
        width: 14px;
        height: 14px;
        font-size: 8px !important;
        margin-top: 4px;
      }
    
      .live-result-item .tie,
      .live-result-item .player,
      .live-result-item .banker {
        line-height: 12px;
      }
}   
