.base_button {
  min-height: 42px;
}

.base_button_primary {
  min-height: 42px;
  background-image: linear-gradient(45deg, #8a3ea7, #5e3ea6);
  border-radius: 20px;
  outline: none;
  border: none;
}

.base_button_primary:hover {
  background-image: linear-gradient(45deg, #8a3ea7, #5e3ea6);
  border-radius: 20px;
}

.base_button_primary:focus {
  background-image: linear-gradient(45deg, #8a3ea7, #5e3ea6);
  border-radius: 20px;
}

.base_button_primary:active {
  background-image: linear-gradient(45deg, #8a3ea7, #5e3ea6);
  border-radius: 20px;
}
