@import 'Buttons/base_button.css';

:root {
  --min-height-header: 60px;
  --min-height-footer: 100px;
  --bg-card-dashboard: #18151e;
  --bg-formula-even: #18151e;
  --bg-formula-odd: #201c28;
  --active-green-color: #76ef48;
  --filter-form-text-color: #888888;
  --filter-form-arrow-color: rgba(255, 255, 255, 0.2);
  --filter-form-search-field-color: rgba(255, 255, 255, 0.1);
  --height-header-dashboard: 120px;
}

.wrapper-common {
  background-image: url('../../assets/images/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.dashboard-center-width {
  max-width: 1300px;
}

#dashboard-header {
  min-height: var(--min-height-header);
  background-color: #000000;
  color: #e4e4e5;
  border-bottom: 1px solid #27212f;
}

#telegram-header {
  min-height: var(--min-height-header);
  background-color: #18151e;
  color: #e4e4e5;
  border-bottom: 1px solid #27212f;
}

#dropdownMenuTelegram,
#menuTelegram {
  background-color: #18151e;
  color: #ffffff;
}

#menuTelegram > a {
  color: #ffffff;
}

#menuTelegram > a:hover,
#menuTelegram > a:focus {
  background-color: #ffffff;
  color: #000000;
}

#dropdownMenuTelegram.dropdown-toggle::after {
  display: none;
}

#dashboard-body {
  min-height: calc(100vh - var(--height-header-dashboard) - var(--min-height-footer));
}

#dashboard-footer {
  min-height: var(--min-height-footer);
  background-color: #000000;
}

#sidebar-menu {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #110022;
  transition: transform 0.5s;
}

.sidebar-menu__show {
  transform: translateX(0%);
}

.sidebar-menu__hide {
  transform: translateX(100%);
}

#sidebar-menu > .sidebar-container > .sidebar_header {
  width: 100%;
  color: white;
  font-size: 18px;
  padding: 12px 24px;
  border-bottom: 1px solid white;
  height: 54px;
}

#sidebar-menu > .sidebar-container > .sidebar_content {
  width: 100%;
  color: white;
  font-size: 18px;
  padding: 24px;
  border-bottom: 1px solid white;
  height: calc(100% - 54px);
}

.card-dashboard {
  background-color: #18151e;
  border-radius: unset;
}

.card-dashboard-title {
  color: #dce3f3;
  border-bottom: 1px solid #3b2764;
}

.card-body {
  color: white;
}

.card-text-success {
  color: var(--active-green-color);
}

.circle-bg-pink {
  background-color: #a0015e;
}

.circle-bg-red {
  background-image: linear-gradient(180deg, #df0900, #a80500);
}

.circle-bg-blue {
  background-image: linear-gradient(180deg, #0db6ed, #09428f);
}

.circle-bg-green {
  background-image: linear-gradient(180deg, #28d309, #1d550a);
}

.circle-bg-orange {
  background: linear-gradient(rgb(255, 125, 0), black);
}

.text-smaller {
  font-size: smaller !important;
}

.text-small {
  font-size: small !important;
}

.text-green {
  color: var(--active-green-color);
}

.text-red {
  color: #e40a00;
}

.text-orange {
  color: #e67548;
}

.color-violet-light {
  color: #e2d1fd;
}

.donutchart-track {
  fill: transparent;
  stroke: #ffffff;
  stroke-width: 26;
}
.donutchart-indicator {
  fill: transparent;
  stroke: #009688;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray 0.3s ease;
}

.donutchart {
  border-radius: 50%;
  display: block;
}

.donutchart-text {
  fill: #ffffff;
}
.donutchart-text-val {
  font-size: 22px;
}
.donutchart-text-percent {
  font-size: 14px;
}
.donutchart-text-label {
  font-size: small;
}

.background-admin {
  background-color: #26202e;
}

.w-40 {
  width: 40%;
}

.bg-green {
  background-color: var(--active-green-color);
}

.w-max-content {
  width: max-content;
}
/* mobile style */

.fz-connect-us {
  font-size: 14px;
}

.d-connect-us {
  display: block;
}

@media (min-width: 768px) {
  .filter-desktop-gray {
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(95%);
    -moz-filter: grayscale(95%);
    -ms-filter: grayscale(95%);
    -o-filter: grayscale(95%);
    filter: grayscale(95%);
  }
}

@media (max-width: 767px) {
  #dashboard-body {
    background-color: #110022;
  }
  .filter-mobile-gray {
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(45%);
    -moz-filter: grayscale(45%);
    -ms-filter: grayscale(45%);
    -o-filter: grayscale(45%);
    filter: grayscale(45%);
  }
}

@media (max-width: 830px) {
  .select-padding-sm.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0 5px;
  }
  .select-padding-sm .ant-select-selection-item {
    text-overflow: clip;
  }

  .option-padding-sm {
    padding: 5px;
  }
}

@media (min-width: 1000px) {
  .lg-w-20 {
    width: 20%;
  }
  .d-connect-us {
    display: flex;
  }
  .fz-connect-us {
    font-size: 16px;
  }
}
