.not-found-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 24px;
}

.not-found-page .title {
  text-align: center;
  color: #ed7d31;
  font-size: 48px;
  width: 100%;
  max-width: 600px;
  padding: 16px;
  border-bottom: 2px solid white;
  margin-bottom: 38px;
}

.not-found-page .note {
  color: #da7230;
  font-size: 28px;
  text-align: center;
  margin: 0px 12px;
}

@media (max-width: 767px) {
  .not-found-page .title {
    font-size: 34px;
    padding: 16px;
    margin-bottom: 24px;
  }

  .not-found-page .note {
    font-size: 24px;
  }
}
